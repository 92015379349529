import { HttpService } from '@core/http';
import { Injectable } from '@angular/core';
import { appSettings } from '@app/configs';
import { Observable, Subscription, map, mergeMap, of, tap } from 'rxjs';
import { CommonService, EncryptionService } from '@core/services';
import { CookieService } from 'ngx-cookie-service';
// import { HttpStatusCode } from '@angular/common/http';
import { browserInfo } from '@app/shared/utilities';
import { RouteParamsService } from '../services/route-params.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private credentials: string = appSettings.credentialsKey;
  private subscriptions: Subscription[] = [];
  private deepLinkingShortId: number | string | undefined;
  constructor(
    private _http: HttpService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _cookieService: CookieService,
    private _routeParamService: RouteParamsService,
    private _encryptionService: EncryptionService
  ) {
    this.subscriptions.push(
      this._routeParamService.routeParamsChange$.subscribe(
        ({ deepLinkingShortId }) => {
          if (deepLinkingShortId) {
            this.deepLinkingShortId =
              this._encryptionService.decryptUsingAES256(
                decodeURIComponent(deepLinkingShortId)
              );
          } else {
            this.deepLinkingShortId = undefined;
          }
        }
      )
    );
  }

  /**
   * *Authenticates with the selected strategy
   * *Stores user info in the storage
   *
   * @param data login credentials
   * @returns observable
   *
   */
  public authenticate(
    data: ILoginData,
    remember_me: boolean
  ): Observable<unknown> {
    const param: IAuthParam = {
      ...data,

      browser_id: browserInfo().browser_id,
      browser_name: browserInfo().browser_name,
      browser_version: browserInfo().browser_version,
      session_id: browserInfo().session_id,
    };
    return this._http.post('user/login-step2', param).pipe(
      mergeMap((result1: any) => {
        const authCodeObj: IAuthCodeInfo = result1.response.dataset;
        return this._http
          .post('user/generate-token', {
            authorization_code: authCodeObj.authorization_code,
          })
          .pipe(
            mergeMap((result2) => {
              const authTokenObj = result2.response.dataset;
              const loginDetailsParam = {
                short_id: this.deepLinkingShortId ?? '',
              };

              return this._httpClient
                .post(
                  environment.host + '/user/view-profile',
                  loginDetailsParam,
                  {
                    // useUrlPrefix: false,
                    headers: {
                      Authorization: 'Bearer ' + authTokenObj.access_token,
                    },
                  }
                )
                .pipe(
                  map((result: any) => {
                    const userDetails = result.response.dataset;
                    const authResponse: ILoginAuthResponse = {
                      ...authTokenObj,
                      ...userDetails,
                    };
                    let authData: any = { ...authResponse };
                    delete authData.deeplink_data; // remove deep linking data
                    const storedData: string = this._cookieService.get(
                      appSettings.rememberKey
                    );
                    this._cookieService.deleteAll();

                    if (remember_me) {
                      this._cookieService.delete(appSettings.rememberKey, '/');
                      this._cookieService.set(
                        appSettings.rememberKey,
                        JSON.stringify(param),
                        {
                          path: '/',
                          expires: 365,
                          sameSite: 'Lax',
                        }
                      );
                    } else {
                      this._cookieService.delete(appSettings.rememberKey, '/');
                    }

                    this._cookieService.set(
                      this.credentials,
                      JSON.stringify({ ...authData }),
                      {
                        path: '/',
                      }
                    );
                    // take token backup
                    this._commonService.saveToken(
                      JSON.stringify({ ...authData })
                    );
                    this.deepLinkingShortId = undefined;

                    return authResponse;
                  })
                );
            })
          );
      })
    );
    // return this._http.post('user/login-step2', param).pipe(
    //   tap((result) => {
    //     const authData: IAuthResponse = result.response.data;

    //     if (result.status === HttpStatusCode.Ok) {
    //       const storedData: string = this._cookieService.get(
    //         appSettings.rememberKey
    //       );
    //       this._cookieService.deleteAll();
    //       if (storedData && remember_me) {
    //         this._cookieService.set(appSettings.rememberKey, storedData, {
    //           path: '/',
    //         });
    //       }
    //       this._cookieService.set(
    //         this.credentials,
    //         JSON.stringify({ ...authData, user_type: user_type }),
    //         {
    //           path: '/',
    //         }
    //       );
    //       // take token backup
    //       this._commonService.saveToken(
    //         JSON.stringify({ ...authData, user_type: user_type })
    //       );
    //     }
    //     return authData;
    //   })
    // );
  }

  /**
   * *Handling forget password
   *
   * @param param email for forget password
   * @returns observable
   *
   */
  public forgetPassword(param: IForgetPasswordParam): Observable<unknown> {
    return this._http.post('user/forgot-password', param);
  }

  /**
   * *Resetting user password
   *
   * @param data for reset pwd
   * @returns observable
   *
   */
  public resetPassword(data: IResetPWDParam): Observable<unknown> {
    return this._http.post('resetPwd', data);
  }

  /**
   * *Creating user password
   *
   * @param data for reset pwd
   * @returns observable
   *
   */
  public createPassword(data: ICreatePWDParam): Observable<unknown> {
    return this._http.post('generatePwd', data);
  }

  /**
   * *getting user from storage
   *
   * @returns current user's data
   *
   */
  public getUser(): IAuthResponse {
    const user = this._cookieService.get(this.credentials);
    const savedCredentials: IAuthResponse =
      user !== '' ? JSON.parse(user) : null;
    // take token backup
    if (user) this._commonService.saveToken(user);
    return savedCredentials;
  }

  /**
   * *Returning current user detail from storage
   *
   * @returns observable of current user
   *
   */
  public getUserInfo(): Observable<IAuthResponse> {
    const savedCredentials: IAuthResponse = this.getUser();
    return of(savedCredentials);
  }

  /**
   * *Getting current user token from cookie
   *
   * @returns JWT Token
   *
   */
  public getToken(): string {
    const savedCredentials: IAuthResponse = this.getUser();
    return savedCredentials != null ? savedCredentials.access_token : '';
  }

  /**
   * *Getting current user type from cookie
   *
   * @returns User Type
   *
   */
  public getUserType(): string {
    const userInfo: IAuthResponse = this.getUser();
    if (userInfo !== null) {
      return userInfo.user_type ? userInfo.user_type : '';
    }
    return '';
  }

  /* Removing current user detail from storage */
  private clearUserInfo() {
    this.deepLinkingShortId = undefined;
    this._cookieService.delete(this.credentials);
    this._cookieService.delete(this.credentials, '/');
  }

  /**
   * *Sign outs user
   * *Removes details from the token storage
   *
   * @returns observable of boolean
   *
   */
  public logout() {
    // if api call is require please call here
    this.clearUserInfo();
  }

  /**
   * *If user is authenticated
   *
   * @returns boolean if authenticated
   *
   */
  public isAuthenticated() {
    if (this._cookieService.get(this.credentials)) {
      return true;
    }
    return false;
  }

  /**
   * *Generate new token
   *
   * @returns refresh token
   *
   */
  public getRefreshToken() {
    const userInfo = this.getUser();
    const param: IRegenerateTokenParam = {
      browser_id: browserInfo().browser_id,
      refresh_token: userInfo.refresh_token,
      browser_name: browserInfo().browser_name,
      browser_version: browserInfo().browser_version,
    };
    return this._http.post('user/regenerate-token', param);
  }

  /**
   * *Updating new tokens in cookie
   *
   * @param authData refresh auth result
   *
   */
  public updateRefreshedToken(authData: IAuthResponse): void {
    const savedCredentials: IAuthResponse = this.getUser();
    // get remember me
    // const rememberMe = this._cookieService.get(appSettings.rememberKey);
    const updated = {
      ...savedCredentials,
      ...authData,
    };
    // delete cookie before delete
    // this._cookieService.deleteAll();
    // set cookie
    // if (rememberMe)
    //   this._cookieService.set(appSettings.rememberKey, rememberMe, {
    //     path: '/',
    //   });
    this._cookieService.set(this.credentials, JSON.stringify(updated), {
      path: '/',
    });
    // take token backup
    this._commonService.saveToken(JSON.stringify(updated));
  }

  public createUserManagementPassword(param: ICreatePasswordParam) {
    return this._http.post('user/create-password', param);
  }

  public resetUserManagementPassword(param: ICreatePasswordParam) {
    return this._http.post('user/reset-password', param);
  }

  /**
   * *Authenticates with the selected strategy
   * *Stores user info in the storage
   *
   * @param data Social login credentials
   * @returns observable
   *
   */
  public socialAuthenticate(data: ISocialLoginData): Observable<unknown> {
    const param: ISocialAuthParam = {
      ...data,
      browser_id: browserInfo().browser_id,
      browser_name: browserInfo().browser_name,
      browser_version: browserInfo().browser_version,
      session_id: browserInfo().session_id,
    };

    return this._http.post('user/social-login', param).pipe(
      tap((result) => {
        const userDetails = result.response.dataset;
        const authResponse: ILoginAuthResponse = {
          ...userDetails,
        };
        const authData = { ...authResponse };

        this._cookieService.deleteAll();
        this._cookieService.set(
          this.credentials,
          JSON.stringify({ ...authData }),
          {
            path: '/',
          }
        );
        // take token backup
        this._commonService.saveToken(JSON.stringify({ ...authData }));
        return authResponse;
      })
    );
  }
}
